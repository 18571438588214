import React, {useEffect, useState} from "react";
import './App.css';
import {useInView} from "react-intersection-observer";

//MUI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DeliveryIcon from '@mui/icons-material/DeliveryDiningOutlined';
import WalkOutIcon from '@mui/icons-material/DirectionsWalkOutlined';
import CheckOutIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';

// We use route in order to define the different routed of our application
import { Router, Routes } from "react-router-dom";

// We import all the components we need in our app
import HideAppBar from "./components/Header";
import CartElements from "./components/CartElements";
import CheckOut from "./components/CheckOut";
import Menu from "./components/Menu";
import StripeCheckOut from "./components/StripeCheckOut";
import Discounts from "./components/Discounts";
import OpeningHoursDialog from "./components/OpeningHoursDialog";
import LoadingAnimation from "./components/LoadingAnimation.js";
import { initGA, logPageView } from './components/analytics';
import AgeCheckDialog from './components/AgeCheckDialog';
import RestaurantSelect from './components/RestaurantSelect';


// Assets
//import bannerImg from "./Images/background.jpeg";// "./Images/banner.avif";
import smiley from "./Images/Elite_smiley.jpeg";

const App = () => {

	//const minPrice = 0;
	//const deliveryPrice = 30;
	let håndteringsGebyr = 3;
	///let freeDeliveryPrice = 250;
	const zipCode = "5700";

	const [loadingPage, setLoadingPage] = useState(true);
	const [trackingID, setTrackingId] = useState(null);

	const [onlyPickUp, setOnlyPickUp] = useState(false);
  	const [sections, setSections] = useState([]);
  	const [resName, setResName] = useState([]);
  	const [resMotto, setResMotto] = useState([]);
  	const [isOpen, setIsOpen] = useState(null);
  	const [closeMsg, setCloseMsg] = useState("");
  	/*const [openingHour, setOpeningHour] = useState(null);
  	const [closingHour, setClosingHour] = useState(null);*/
  	const [deliveryPrice, setDeliveryPrice] = useState(0);
  	const [freeDeliveryPrice, setFreeDeliveryPrice] = useState(0);
  	const [discount_rate, set_discount_rate] = useState(0);
  	const [contactInfo, setContactInfo] = useState({});
  	const [restaurantId, setRestaurantId] = useState("");
  	const [minPrice, setMinOrderPrice] = useState(0);
  	const [bannerImg, setBannerImg] = useState(0);
  	const [logoImg, setLogoImg] = useState(0);
  	const [discountText1, setDiscountText1] = useState("");
  	const [discountText2, setDiscountText2] = useState("");
  	const [ageRestricted, setAgeRestricted] = useState(false);

  	const [restaurantSelected, setRestaurantSelected] = useState("localhost");

	const [cart, setCart] = useState([]);
	const [cartId, setCartId] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);

	const [delivery, setDelivery] = useState(false);
	const [deliveryDiscount, setDeliveryDiscount] = useState(false);

	//for checkout dialoug
	const [open, setOpen] = React.useState(false);
	const [openCheckOut, setOpenCheckOut] = React.useState(false);
	const [openPayment, setOpenPayment] = React.useState(false);

	/******Collecting Check Out Data and Errors******/
	//const [submitted, setSubmitted] = useState(false);
	const [checkOutData, setCheckOutData] = useState({
		address: "",
		postNum:"",
		email:"",
		telf:"",
		fullName:"",
		delivery:"",
		isPlanningForLater:false,
		selectedDay:"",
		selectedTime:""
	});
	const [missingField, setMissingField] = useState(false);
	const [errorText, setErrorText] = useState("");

	//function handleClickSubmitCheckOut(){
	//	setSubmitted(true);
	//}
	/**************** Data and Errors ***************/


	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenCheckOut = () => {
		setOpenCheckOut(true);
	};

	const handleCloseCheckOut = () => {
		setOpenCheckOut(false);
	};


	const handleClickOpenPayment = () => {
		setOpenPayment(true);
	};

	const handleClosePayment = () => {
		setOpenPayment(false);
	};

	useEffect(() => {
	  //let domain = window.location.hostname.replace(/\.com$/, '');
	  let domain = window.location.hostname.replace(/\.(com|dk)$/, '');
	  if (domain.startsWith('www.')) {
  		domain = domain.replace('www.', '');
	  }

	  if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'staging') {
		domain = restaurantSelected;
	  }
	  console.log(domain);

	  fetch(process.env.REACT_APP_API_ADDRESS, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify({domain: domain}),
		})
		.then(response => response.json())
		.then(data => {
		        console.log(data); // Log the entire response data


			    // Get the current time in the Danish timezone
			    const currentDanishTime = new Date().toLocaleString("en-US", { timeZone: "Europe/Copenhagen" });
			    const currentDate = new Date(currentDanishTime);
			    const currentDay = currentDate.toLocaleDateString('da-DK', { weekday: 'long' }).toLowerCase();
			    
			    // Function to convert HH:MM time string to minutes for easier comparison
			    const timeToMinutes = (time) => {
			      const [hours, minutes] = time.split(':').map(Number);
			      return hours * 60 + minutes;
			    };
			    
			    const currentHours = currentDate.getHours();
			    const currentMinutes = currentDate.getMinutes();
			    const currentTotalMinutes = currentHours * 60 + currentMinutes;

			    // Access the schedule for the current day
			    const daySchedule = data.opening_hours.scheduled_opening_hours[currentDay];
			    if (!daySchedule) {
			      console.error('No schedule found for today');
			      setOnlyPickUp(true);
			      return;
			    }

			    // Ensure daySchedule.intervals is an array
			    if (!Array.isArray(daySchedule.intervals)) {
			      console.error('Invalid schedule format for today');
			      setOnlyPickUp(true);
			      return;
			    }

			    // Variable to track if delivery is available at the current time
			    let deliveryAvailable = false;

			    // Iterate over each interval in the day's schedule
			    daySchedule.intervals.forEach(interval => {
			      const deliveryOpenTime = interval.delivery_open;
			      const deliveryCloseTime = interval.delivery_close;

			      // Check if delivery times are not 'Closed'
			      if (deliveryOpenTime !== 'Closed' && deliveryCloseTime !== 'Closed') {
			        const deliveryOpenMinutes = timeToMinutes(deliveryOpenTime);
			        const deliveryCloseMinutes = timeToMinutes(deliveryCloseTime);

			        // Check if current time is within the delivery interval
			        if (currentTotalMinutes >= deliveryOpenMinutes && currentTotalMinutes <= deliveryCloseMinutes) {
			          deliveryAvailable = true;
			        }
			      }
			    });

			    // Set the onlyPickUp state based on delivery availability
			    setOnlyPickUp(!deliveryAvailable);



		        //console.log(data.menu.menu); // Log the menu property
		        setSections(data.menu);
		        setResName(data.name);
		        setResMotto(data.motto);
		        /*const openingHour = Number(data.opening_hours.start.split(":")[0]);
		        const closingHour = Number(data.opening_hours.end.split(":")[0]);
		        setOpeningHour(openingHour);
		        setClosingHour(closingHour);
		        console.log(Number(data.opening_hours.start.split(":")[0]));
		        console.log(Number(data.opening_hours.end.split(":")[0]));*/
		        setDeliveryPrice(parseInt(data.delivery_info.delivery_price));
		        setFreeDeliveryPrice(parseInt(data.delivery_info.free_delivery_min_order));
		        set_discount_rate(parseInt(data.delivery_info.fixed_discount_rate));
		        setMinOrderPrice(parseInt(data.delivery_info.min_order_price));
		        setContactInfo(data.contact_info);
		        setRestaurantId(data.restaurantId);
		        setTrackingId(data.googleAnalyticsTrackingID);
		        setBannerImg(data.images.banner);
		        setLogoImg(data.images.logo);
		        setDiscountText1(data.discount_text_1);
		        setDiscountText2(data.discount_text_2);
		        setAgeRestricted(data.age_restricted);

		        //call the REACT_APP_OPENING_HOURS_API
		        console.log(`${process.env.REACT_APP_OPENING_HOURS_API}/GetHours/${data.restaurantId}`);
		        fetch(`${process.env.REACT_APP_OPENING_HOURS_API}/GetHours/${data.restaurantId}`, {
		        	method: "GET", //The API endpoint uses GET method
		        })
		        .then(responseOpeningHours => responseOpeningHours.json())
		        .then(dataOpeningHours => {
		        	console.log(dataOpeningHours);
		        	if(dataOpeningHours.status == "Open"){
		        		console.log("restaurant open");
		        		setIsOpen(true);
		        		setCloseMsg(dataOpeningHours.message);
		        	} else {
		        		console.log("restaurant close");
		        		setIsOpen(false);
		        		setCloseMsg(dataOpeningHours.message);
		        	}
		        });

		      })
		.catch(error => console.log(error));

	},[restaurantSelected]);

	useEffect(() => {
	  initGA(trackingID).then(() => {
	    logPageView(trackingID);
	  });
	}, [trackingID]);

	useEffect(() => {
	  console.log(restaurantId);
	  //console.log(closingHour);

	},[restaurantId]);


	useEffect(() => {
		let newTotalPrice = 0;
		for(let i = 0; i < cart.length; i++){
			newTotalPrice += cart[i].price;
		}
		newTotalPrice += håndteringsGebyr;
		if(delivery && (newTotalPrice < (freeDeliveryPrice + håndteringsGebyr) ) ){
			setDeliveryDiscount(false);
			newTotalPrice = newTotalPrice + deliveryPrice;

			newTotalPrice = parseFloat((newTotalPrice * (1 - (discount_rate / 100))).toFixed(2));
			setTotalPrice(newTotalPrice);
		} else{
			setDeliveryDiscount(true);

			newTotalPrice = parseFloat((newTotalPrice * (1 - (discount_rate / 100))).toFixed(2));
			setTotalPrice(newTotalPrice);
		}

		//console.log(newTotalPrice);
	},[cart, delivery]);

	useEffect(() => {

		const currentTime = new Date();
		let currentHour = null;
		//if (process.env.NODE_ENV === 'development') {
		//	currentHour = 16;
		//} else {
			currentHour = currentTime.getHours();
		//}
		//let currentHour = 16;

		console.log(minPrice);
		console.log(delivery);

		//just make a simple isOpen and closingMsg (if closed)
		if (!isOpen && checkOutData["isPlanningForLater"] && (checkOutData["selectedDay"] === "" || checkOutData["selectedTime"] === "")  /*currentHour < openingHour || currentHour > closingHour*/) {
			console.log(restaurantId);
			console.log("********** HOURS **********")
			console.log(currentHour);
			/*console.log(openingHour);
			console.log(closingHour);*/
			console.log("********** HOURS **********")
			setMissingField(true);
			//setErrorText(closeMsg);
			setErrorText("Udfyld dag og tid for forudbestilling, \n" + closeMsg);
		} else if( ( (checkOutData["address"] === '' && delivery) || (checkOutData["postNum"] === '' && delivery ) || checkOutData["email"] === '' || checkOutData["telf"] === '' || checkOutData["fullName"] === '') ){
			//console.log("missing field");
			setMissingField(true);
			setErrorText("Udfuld alle felter");
		}else if( delivery && (totalPrice-deliveryPrice < minPrice) ){
			setMissingField(true);
			setErrorText("Du skal bestille mad for mere end " + minPrice + "kr før vi køre ud med det." + minPrice.toString() );
		}else if( (totalPrice < minPrice) && delivery ){
			//console.log("total is too low");
			setMissingField(true);
			setErrorText("Der kan kun bestilles for min. " + minPrice.toString() );
		}
		else if( checkOutData["isPlanningForLater"] && (checkOutData["selectedDay"] === "" || checkOutData["selectedTime"] === "") ){
			//console.log("total is too low");
			setMissingField(true);
			setErrorText("Vælg dag og tid for planlagt forudbestiling.");
		}
		else if( cart.length == 0 ){
			setMissingField(true);
			setErrorText("Hust at vælg din mad.");
		}
		else{
		 	handleClickOpenPayment();
			//console.log("form completed");
		}
		/*else if( (checkOutData["postNum"] != zipCode)){
			//console.log("wrong zip");
			setMissingField(true);
			setErrorText("Der kan kun bestilles med post nummeret " + zipCode);
		}*/
	}, [checkOutData, restaurantId]);


	async function handleClickFood(price, item){
		//setCart(event.target.value)
		const newCart = [...cart, {id:cartId,food:item,price:price}];
		setCartId(cartId+1);//would be more comfortable if this was a synchronous action
		setCart(newCart);
		//console.log(price);
		//console.log(item);
	}

	function removeFromCart(id){
		setCart(currentCart => {
			return currentCart.filter(item => {return item.id !== id;})
		});
		console.log("remove id: " + id);
	}



	return (
		<div className="App">
			<div id="bannerImg">

				<div id="bannerImg">
				  {bannerImg && <img src={`https://storage.googleapis.com/tas-images/Banners/${bannerImg}`} style={{ "position": "absolute", "width": "100vw", "height": "75vh", "object-fit": "cover", "zIndex":"-2" }} alt="venueBackGround" />}
				  <div style={{ "width": "100vw", "height": "75vh", "position": "absolute", "backgroundColor": "#0000003d", "zIndex":"-1" }} >
				    {/*<Container maxWidth="lg" style={{ "display": "flex", "justifyContent": "space-around", "alignItems": "center", "height": "100%" }}>
				      <div style={{ "display": "flex", "alignItems": "center", "color": "white", "textShadow": "1px 1px #000" }}>
				        <FastfoodIcon style={{ "marginRight": "2.0rem" }} />
				        <Typography className="font-link" style={{ fontSize: "2rem", fontWeight: "bold" }} >Free delivery for orders over 200 kr</Typography>
				      </div>
				      <div style={{ "display": "flex", "alignItems": "center", "color": "white", "textShadow": "1px 1px #000" }}>
				        <CardGiftcardIcon style={{ "marginRight": "2.0rem"}} />
				        <Typography className="font-link" style={{ fontSize: "2rem", fontWeight: "bold" }} >10% Discount for online purchases</Typography>
				      </div>
				    </Container>*/}
				  </div>
				</div>


			</div>

			<HideAppBar logoImg={logoImg} contactInfo={contactInfo} />
			<div style={{"position":"absolute", "width":"100vw", "overflow":"clip"}}>
				<Container maxWidth="lg">
					<Box style={{"position":"fixed","bottom":"30vh","zIndex":"-1"}} >
						<h1 className="res-name" style={{"color":"white", "fontWeight":"700", "fontSize":"5.5rem","margin":"0px"}} >{resName}</h1>
						<h3 className="font-link" style={{"color":"white","fontFamily": 'WoltHeading-Omnes, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif', "fontWeight":"700", "fontSize":"2rem", "margin":"0px"}} >{resMotto}</h3>
						{/*<h5 className="font-link" style={{"color":"#202125","backgroundColor":"white","fontWeight":"700","width":"fit-content", "padding":"10px", "borderRadius":"4px"}}> MIN. ORDER {minPrice} kr </h5>*/}
					</Box>

					<div style={{"padding":"32px", "backgroundColor":"white", "borderRadius":"8px", "boxShadow":"0 2px 10px #20212536", "marginTop":"70vh"}} >
						{discountText1 && discountText2 && <Discounts discountText1={discountText1} discountText2={discountText2} />}			
				    </div>

					<div id="mainContent" style={{"backgroundColor":"white"}}>
						<Grid container columnSpacing={{ xs: 0, sm: 0, md: 2 }} style={{"paddingTop":"5%"}}>

					  		<RestaurantSelect 
							  restaurants={["alforno-pizza-svendborg", "localhost", "shishaonline", "test", "pizza2go", "zaraspizzasvendborg", "svendborgspisested"]} 
							  restaurantSelected={restaurantSelected} 
							  setRestaurantSelected={setRestaurantSelected} 
							/>
							{sections ? (sections.length > 0 ? <Menu sections={sections} handleClickFood={handleClickFood} /> : <LoadingAnimation />) : null}

							<Grid sx={0} md={4} >
								<Box style={{"marginLeft":"auto","marginRight":"auto","top":"100px","position":"sticky", "width":"80%"}} sx={{display: {xs: 'none', md: 'block'}}} >
									<h3 className="font-link" >Din Ordre</h3>
									<Stack direction="row" spacing={2} justifyContent="center" style={{"marginBottom":"10px"}} >
								        <Button variant="outlined" startIcon={<WalkOutIcon />} variant={!delivery ? "contained" : "outlined"} onClick={() => setDelivery(false)} size="large">
								        	Afhentning 
								        </Button>

								        <Button variant="outlined" disabled={onlyPickUp} startIcon={<DeliveryIcon />} variant={delivery ? "contained" : "outlined"} onClick={() => setDelivery(true)} size="large">
								        	Levering 
								        </Button>
									</Stack>
									<CartElements items={cart} onDelete={removeFromCart} delivery={delivery} deliveryPrice={deliveryPrice} discountRate={discount_rate} deliveryDiscount={deliveryDiscount} totalPrice={totalPrice} />
									<div>
								        <Button variant="outlined" startIcon={<CheckOutIcon />} onClick={handleClickOpenCheckOut} style={{"marginTop":"10px", "width":"100%"}} size="large">
								        	{ isOpen ? "Check Ud" : "Forudbestil" }
								        </Button>
									</div>
								</Box>
							</Grid>
						</Grid>

						{ cart.length &&
							<Button variant="outlined" onClick={handleClickOpen} startIcon={<CheckOutIcon />} sx={{position: 'fixed', bottom: 10, borderRadius:"5px", width: '90vw', height: 40, textAlign: 'left', padding:0, backgroundColor: "#009de0", color:"white", display: {xs:"inline-flex",md:"none"}, textTransform: 'none'}} size="large">
					        	({cart.length}) { isOpen ? "Check Ud" : "Forudbestil" } {totalPrice} kr
					        </Button>
					    }
					
					</div>

				</Container>



				{/* MOBILE CHECKOUT DIALOUG */}
				<Dialog
			        fullWidth="true"
			        maxWidth="true"
			        paperFullWidth
			        open={open}
			        onClose={handleClose}

  			      >
		        	<DialogTitle className="font-link">Din Ordre</DialogTitle>

		          	<DialogContentText className="font-link">
		          	</DialogContentText>
					<Box
						noValidate
						component="form"
						sx={{
						  display: 'flex',
						  flexDirection: 'column',
						  m: 'auto',
						  width: '100%',
						  margin:"auto",
						}}

  						alignItems="center"
  						justifyContent="center"
					>

						<Stack direction="row" spacing={2} justifyContent="center" style={{"marginBottom":"10px","width":"90%"}} >
					        <Button variant="outlined" startIcon={<WalkOutIcon />} variant={!delivery ? "contained" : "outlined"} onClick={() => setDelivery(false)} size="large">
					        	Afhentning 
					        </Button>

					        <Button variant="outlined" disabled={onlyPickUp} startIcon={<DeliveryIcon />} variant={delivery ? "contained" : "outlined"} onClick={() => setDelivery(true)} size="large">
					        	Levering 
					        </Button>
						</Stack>

						<CheckOut isOpen={isOpen} restaurantId={restaurantId} checkOutData={checkOutData} delivery={delivery} setCheckOutData={setCheckOutData} >
							<CartElements items={cart} onDelete={removeFromCart} delivery={delivery} discountRate={discount_rate} deliveryDiscount={deliveryDiscount} deliveryPrice={deliveryPrice} totalPrice={totalPrice} sx={{width:"16vw"}} /> 
						</CheckOut>

						<Typography variant="h6" sx={{color:"red"}} display={missingField ? "block" : "none"}  gutterBottom>
							{errorText}
						</Typography>
					</Box>

			        <DialogActions>
			          <Button onClick={handleClose}>Close</Button>
			        </DialogActions>
		      	</Dialog>


	      	    <style>
			      {`
			        .MuiDialog-paper {
			          position: relative;
			        }
			        .MuiDialog-paper::before {
			          content: '';
			          position: absolute;
			          // ... rest of the styles
			        }
			      `}
			    </style>

				{/* DESKTOP BROWSER CHECKOUT DIALOUG */}
				<Dialog

					fullWidth
					maxWidth="sm"
			        open={openCheckOut}
			        onClose={handleCloseCheckOut}

  					alignItems="center"
  					justifyContent="center"

			    	PaperProps={{
				         style: {
					     	backdropFilter: 'blur(10px)',
						    borderRadius: '50px',
						    opacity: 1,
						    border: '1px solid rgba(255, 255, 255, 0.5)',
					      	boxShadow: `
					        	0px -1px 0px 0px rgba(255, 0, 0, 0.5),  // Top border
					        	0px 1px 0px 0px rgba(0, 255, 0, 0.5),   // Bottom border
					        	-1px 0px 0px 0px rgba(0, 0, 255, 0.5),  // Left border
					        	1px 0px 0px 0px rgba(255, 255, 0, 0.5)  // Right border
					    		`,
      					}
				    }}
			      >

		          	<DialogContentText className="font-link">
		          	</DialogContentText>
					<Box
						noValidate
						component="div"
						sx={{
						  display: 'flex',
						  flexDirection: 'column',
						  m: 'auto',
						  width: '100%',
						}}

						alignItems="center"
						justifyContent="center"
					>
		        		<DialogTitle className="res-name"   
		        			style={{ 
						    	color: "white", 
						    	fontSize: 35, 
						    	fontFamily: "'Sansita Swashed', cursive" 
						  	}} >Din Ordre</DialogTitle>

						
						<CheckOut isOpen={isOpen} restaurantId={restaurantId} checkOutData={checkOutData} delivery={delivery} setCheckOutData={setCheckOutData} >
							<CartElements items={cart} onDelete={removeFromCart} delivery={delivery} discountRate={discount_rate} deliveryDiscount={deliveryDiscount} deliveryPrice={deliveryPrice} totalPrice={totalPrice} sx={{width:"16vw"}} /> 
						</CheckOut>

						<Typography variant="h6" sx={{color:"red"}} display={missingField ? "block" : "none"}  gutterBottom>
							{errorText}
						</Typography>

					</Box>

			        <DialogActions>
			          	<Button onClick={handleCloseCheckOut}>Close</Button>
			        </DialogActions>
		      	</Dialog>


		      	{/* Stripe checkout */}
		      	<Dialog

					fullWidth
					maxWidth="sm"
			        open={openPayment}
			        onClose={handleClosePayment}

  					alignItems="center"
  					justifyContent="center"
			      >

		          	<DialogContentText className="font-link">
		          	</DialogContentText>
					<Box
						noValidate
						component="div"
						sx={{
						  display: 'flex',
						  flexDirection: 'column',
						  m: 'auto',
						  width: '100%',
						}}

						alignItems="center"
						justifyContent="center"
					>
		        		<DialogTitle className="font-link">Betaling</DialogTitle>

						<StripeCheckOut restaurantId={restaurantId} items={cart} orderData={checkOutData} setErr={setErrorText} />

					</Box>

			        <DialogActions>
			          	<Button onClick={handleClosePayment}>Close</Button>
			        </DialogActions>
		      	</Dialog>

		      	{ageRestricted && <AgeCheckDialog />}

		      	{/* isOpen !== null && !isOpen && <OpeningHoursDialog isOpen={isOpen} closeMsg={closeMsg} />*/}

			</div>

		</div>
	);

};

export default App;