import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const pages = ['Kontakt'];
const link = ['Kontakt'];

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: "50px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  backdropFilter: "blur(8px)",
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '22ch',
      },
    },
  },
}));

const SearchBlack = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: "50px",
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapperBlack = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: "black"
}));

const StyledInputBaseBlack = styled(InputBase)(({ theme }) => ({
  color: 'black',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '15ch',
      '&:focus': {
        width: '18ch',
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '20ch',
      '&:focus': {
        width: '22ch',
      },
    },
  },
}));

// Added custom mobile menu styling
const StyledMobileMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: '150px', // Ensure minimum width for mobile menu
    width: 'auto',     // Allow menu to expand based on content
    maxWidth: '80vw',  // Prevent menu from getting too wide
  },
  '& .MuiMenuItem-root': {
    padding: theme.spacing(1, 2), // Add more padding for touch targets
  }
}));

export default function HideAppBar(props) {
  const [open, setOpen] = React.useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleClick = () => {
    setShowPhoneNumber(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    console.log(props.contactInfo);
  }, [props.contactInfo]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (page) => {
    if (page === 'Kontakt') {
      handleClickOpen();
    }
    handleCloseNavMenu();
  };

  const shrink = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 30,
  });

  let [Logo, setLogo] = React.useState("");
  let [BlackLogo, setBlackLogo] = React.useState("");

  React.useEffect(() => {
    setLogo(props.logoImg);
    setBlackLogo(props.logoImg);
  }, [props.logoImg]);

  let toolbarColor = shrink ? { background: "white" } : { background: "transparent" };
  let toolbarTextColor = shrink ? "black" : "white";
  let menuIconBtnColor = shrink ? "primary" : "inherit";
  let LogoIcon = shrink ? BlackLogo : Logo;

  return (
    <>
      <AppBar style={{ ...toolbarColor, boxShadow: "none" }}>
        <Container maxWidth="lg" sx={{ padding: { xs: "0 16px", md: "0" } }}>
          <Toolbar disableGutters>
            {/* Menu for mobile screens */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
              <IconButton
                size="large"
                aria-label="navigation menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color={menuIconBtnColor}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <StyledMobileMenu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem 
                    key={page} 
                    onClick={() => handleMenuItemClick(page)}
                    sx={{ width: '100%' }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </StyledMobileMenu>
            </Box>

            {/* Logo */}
            <Box sx={{ flexGrow: 1, textAlign: "left" }}>
              <img 
                src={`https://storage.googleapis.com/tas-images/Logos/${LogoIcon}`} 
                style={{ height: "5vh" }} 
                alt="toolbar logo" 
              />
            </Box>

            {/* Menu Buttons for desktop */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
              {pages.map((page, index) => (
                <Button
                  key={page}
                  onClick={page === 'Kontakt' ? handleClickOpen : handleCloseNavMenu}
                  href={"#" + link[index]}
                  sx={{ 
                    my: 2, 
                    color: toolbarTextColor, 
                    display: 'block',
                    marginLeft: "2vw", 
                    fontWeight: "bold" 
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Contact Dialog */}
      <Dialog 
        open={open} 
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{"Kontakt"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Addresse: {props.contactInfo?.address || 'Not available'}</p>
            {showPhoneNumber ? (
              <p>Phone Number: {props.contactInfo?.phone_number || 'Not available'}</p>
            ) : (
              <button 
                onClick={handleClick} 
                style={{
                  backgroundColor: 'white', 
                  color: '#636363',
                  border: '1px solid #ccc',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Vis telefon nummer
              </button>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

HideAppBar.propTypes = {
  contactInfo: PropTypes.shape({
    address: PropTypes.string,
    phone_number: PropTypes.string
  }),
  logoImg: PropTypes.string
};

HideAppBar.defaultProps = {
  contactInfo: {
    address: '',
    phone_number: ''
  },
  logoImg: ''
};