import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CheckOutIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import { Select, MenuItem, ButtonGroup, FormGroup, FormControlLabel, FormControl, InputLabel } from '@mui/material';
import Cookies from 'js-cookie'; // Import Cookies
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';


import { makeStyles } from '@material-ui/core/styles';

import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import axios from 'axios';

import CartElement from "./CartElements";
import PlaceAutocomplete from './PlaceAutocomplete';


const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: '#f5f5f5',  // Grey background
    borderRadius: 40,             // Rounded corners
    margin: theme.spacing(1),    // Margin
  },
  outlinedInput: {
    border: "none",              // Remove border
    "&:hover": {
      border: "none"             // Remove border on hover
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  }
}));


export default function CheckOut(props){

	const classes = useStyles();


	const [isPlanningForLater, setIsPlanningForLater] = useState(false);
	const [openingHours, setOpeningHours] = useState({});
	const [availableHours, setAvailableHours] = useState([]);
	const [tempClosureDates, setTempClosureDates] = useState([]);

	const [submitted, setSubmitted] = useState("");
	const [address, setAddress] = useState("");
	const [postNum, setPostNum] = useState("");
	const [email, setEmail] = useState("");
	const [telf, setTelf] = useState("");
	const [fullName, setFullName] = useState("");
	const [comments, setComments] = useState("");
	const delivery = props.delivery;


  const [selectedDay, setSelectedDay] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  // In CheckOut component:
	const [isAddressValid, setIsAddressValid] = useState(false); // Assume true by default
	const [addressError, setAddressError] = useState("");



	// Utility function to generate an array of days for the dropdown
	const getDays = () => {
	  const weekdays = ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"];
	  let days = [];

	  for (let i = 0; i < 7; i++) {
	    const day = new Date();
	    day.setDate(day.getDate() + i);
	    const dayOfWeek = weekdays[day.getDay()];
	    const dayString = day.toISOString().split('T')[0];

	    // Safely handle tempClosureDates being null or undefined
	    const isTempClosed = Array.isArray(tempClosureDates) && tempClosureDates.length > 0 &&
	      dayString >= tempClosureDates[0] && dayString <= tempClosureDates[1];

	    // Check if the day is marked as closed
	    const isClosed = (openingHours[dayOfWeek] && openingHours[dayOfWeek].isOpen === false) || isTempClosed;

	    const dayLabel = i === 0 ? "I dag" : i === 1 ? "I morgen" : dayOfWeek;
	    
	    days.push({
	      label: isClosed ? `${dayLabel} (Lukket)` : dayLabel,
	      value: day.toISOString().split('T')[0], // Keeping the date value in YYYY-MM-DD format
	      isClosed: isClosed
	    });
	  }

	  console.log(days);
	  return days;
	};


  // Assuming the hours are from 8:00 to 20:00, adjust according to your needs
  const hours = Array.from({ length: 13 }, (_, i) => 8 + i);


	const generateTimeOptions = (startHour, startMin, endHour, endMin, includeCurrentDay = false) => {
			console.log("STARTMIN: ", startMin);
	    const times = [];
	    let currentHour = new Date().getHours();
	    let currentMinute = new Date().getMinutes();

	    // Adjust for current day by setting start time one hour ahead
	    if (includeCurrentDay) {
	        currentMinute += 60; // Add 60 minutes to the current time
	        if (currentMinute >= 60) {
	            currentHour++;
	            currentMinute %= 60; // Adjust minutes after incrementing hour
	        }
	        // Round up to the next 5-minute interval
	        if (currentMinute % 5 !== 0) {
	            currentMinute += 5 - (currentMinute % 5);
	        }
	        if (currentMinute === 60) {
	            currentHour++;
	            currentMinute = 0;
	        }
	    }

	    // Start from the later of the opening time or the current time adjusted for today
	    let startCompHour = includeCurrentDay ? Math.max(currentHour, startHour) : startHour;
	    let startCompMinute = currentHour > startHour && includeCurrentDay ? currentMinute : startMin;

	    // Adjust starting minute for the first slot based on the current time
	    if (includeCurrentDay && startCompHour === currentHour) {
	        startCompMinute = currentMinute;
	    }

	    let hour = startCompHour;
	    let minute = startCompMinute;

	    while (true) {
	        if (hour > endHour || (hour === endHour && minute > endMin)) break; // Ensure within closing time
	        const timeString = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
	        times.push(timeString);

	        minute += 5;
	        if (minute >= 60) {
	            minute = 0;
	            hour++;
	        }
	    }

	    return times.filter(time => {
	        const [hrs, mins] = time.split(':').map(Number);
	        return hrs > startHour || (hrs === startHour && mins >= startMin) // After opening
	            && (hrs < endHour || (hrs === endHour && mins <= endMin)); // Before closing
	    });
	};


  // Adjusted getHoursForDay to consider if selectedDay is today
  const getHoursForDay = (day) => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    const isToday = day === today;
    
    const dayOfWeek = new Date(day).getDay();
    const weekdays = ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"];
    const dayName = weekdays[dayOfWeek];
    const hoursInfo = openingHours[dayName]; // Assuming openingHours is structured appropriately

    if (!hoursInfo  || !hoursInfo.isOpen || (tempClosureDates && tempClosureDates.includes(day))) return [];

	  let availableTimes = [];

	  hoursInfo.intervals.forEach(interval => {
	    const [startHour, startMin] = interval.open.split(':').map(Number);
	    const [endHour, endMin] = interval.close.split(':').map(Number);

	    const intervalTimes = generateTimeOptions(startHour, startMin, endHour, endMin, isToday);
	    availableTimes = availableTimes.concat(intervalTimes);
	  });

    return availableTimes;
  };


	useEffect(() => {
	  if (selectedDay) {
	    const hours = getHoursForDay(selectedDay);
	    setAvailableHours(hours); // This updates the availableHours state
	  } else {
	    setAvailableHours([]); // Resets available hours if no day is selected
	  }

	  console.log("OpeningHours: ", openingHours);
	}, [selectedDay, openingHours, , tempClosureDates]);


	useEffect(() => {
	  fetch(`${process.env.REACT_APP_OPENING_HOURS_API}/GetPreOrderHours/${props.restaurantId}`, {
	    method: "GET",
	  })
	    .then(response => response.json())
	    .then(data => {
	    	//console.log(data);
	    	//console.log("TempClosure" + data.temporarilyClosed);
	      setOpeningHours(data.hoursData.scheduled_opening_hours);
	      setTempClosureDates(data.temporarilyClosed);
	    });
	}, [props.restaurantId]);


	// Inside your component
	useEffect(() => {
	  // Automatically set to 'Planlæg til senere' if props.isOpen is false
	  if (!props.isOpen) {
	    setIsPlanningForLater(true);
	  }
	}, [props.isOpen]);


	useEffect(() => {
	  // Load user info from cookies when the component mounts
	  const userInfo = {
	    address: Cookies.get('address'),
	    postNum: Cookies.get('postNum'),
	    email: Cookies.get('email'),
	    telf: Cookies.get('telf'),
	    fullName: Cookies.get('fullName')
	  };

	  if (userInfo.address) setAddress(userInfo.address);
	  if (userInfo.postNum) setPostNum(userInfo.postNum);
	  if (userInfo.email) setEmail(userInfo.email);
	  if (userInfo.telf) setTelf(userInfo.telf);
	  if (userInfo.fullName) setFullName(userInfo.fullName);

	  // If an address is found, revalidate it
	  if (userInfo.address) {
	    getGeocode({ address: userInfo.address })
	      .then(results => {
	        const addressComponents = results[0].address_components;
	        const streetNumber = addressComponents.find(comp => comp.types.includes("street_number"));
	        const route = addressComponents.find(comp => comp.types.includes("route"));

	        if (streetNumber && route) {
	          setIsAddressValid(true);
	          setAddressError("");
	        } else {
	          setIsAddressValid(false);
	          setAddressError("Gemte adresse er ikke komplet. Vælg venligst en ny adresse.");
	        }
	      })
	      .catch(() => {
	        setIsAddressValid(false);
	        setAddressError("Kunne ikke verificere den gemte adresse. Vælg venligst en ny adresse.");
	      });
	  } else {
	    // If no address in cookie, just set invalid so user must pick a new one
	    setIsAddressValid(false);
	  }
	}, []);


	useEffect(() => {
	  // Update cookies whenever user info changes
	  Cookies.set('address', address, {expires: 365, secure: true, sameSite: 'None', path: '/' });
	  Cookies.set('postNum', postNum, {expires: 365, secure: true, sameSite: 'None', path: '/' });
	  Cookies.set('email', email, {expires: 365, secure: true, sameSite: 'None', path: '/' });
	  Cookies.set('telf', telf, {expires: 365, secure: true, sameSite: 'None', path: '/' });
	  Cookies.set('fullName', fullName, {expires: 365, secure: true, sameSite: 'None', path: '/' });
	}, [address, postNum, email, telf, fullName]);



	function submit() {
	  setSubmitted(true);

	  if (delivery && !isAddressValid) {
	    // addressError already set by PlaceAutocomplete.
	    // Just stop the submission here:
	    return;
	  }

	  props.setCheckOutData({
	    address: address,
	    postNum: postNum,
	    email: email,
	    telf: telf,
	    fullName: fullName,
	    delivery: delivery.toString(),
	    isPlanningForLater:isPlanningForLater,
	    selectedDay:selectedDay,
	    selectedTime:selectedTime,
	    comments:comments
	  });
	}

	

	return <>
		<Box component="div" sx={{width:{xs:"90%", md:"18vw"} }} alignItems="center" justifyContent="center">
			{delivery && <PlaceAutocomplete glassUI={props.glassUI} setAddress={setAddress} theValue={address} setIsAddressValid={setIsAddressValid} setAddressError={setAddressError} addressError={addressError} />}
			{delivery && <TextField id="standard-basic" sx={props.glassUI === "true" ? {
				marginTop: "15px",
				marginBottom: "15px",
			    input: { color: 'white' }, // Text color
			    '& label': {
			      color: 'white', // Label color
			    },
			    '& .MuiInput-underline:before': {
			      borderBottomColor: 'white', // Underline color before focus
			    },
			    '& .MuiInput-underline:after': {
			      borderBottomColor: 'white', // Underline color after focus
			    },
			    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			      borderBottomColor: 'white', // Underline color when hovering
			    },
			    '& .MuiFormHelperText-root': {
			      color: 'white', // Helper text color
			    },
			  }:{}}  label="Post Nummer" variant="standard" onChange={e => setPostNum(e.target.value)} value={postNum} error={!postNum && submitted && delivery} fullWidth />}

			<TextField id="standard-basic" sx={props.glassUI === "true" ? {
				marginBottom: "15px",
			    input: { color: 'white' }, // Text color
			    '& label': {
			      color: 'white', // Label color
			    },
			    '& .MuiInput-underline:before': {
			      borderBottomColor: 'white', // Underline color before focus
			    },
			    '& .MuiInput-underline:after': {
			      borderBottomColor: 'white', // Underline color after focus
			    },
			    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			      borderBottomColor: 'white', // Underline color when hovering
			    },
			    '& .MuiFormHelperText-root': {
			      color: 'white', // Helper text color
			    },
			  } : {}} label="Email" variant="standard" fullWidth onChange={e => setEmail(e.target.value)} value={email} error={!email && submitted} />

			<TextField id="standard-basic" sx={props.glassUI === "true" ? {
				marginBottom: "15px",
			    input: { color: 'white' }, // Text color
			    '& label': {
			      color: 'white', // Label color
			    },
			    '& .MuiInput-underline:before': {
			      borderBottomColor: 'white', // Underline color before focus
			    },
			    '& .MuiInput-underline:after': {
			      borderBottomColor: 'white', // Underline color after focus
			    },
			    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			      borderBottomColor: 'white', // Underline color when hovering
			    },
			    '& .MuiFormHelperText-root': {
			      color: 'white', // Helper text color
			    },
			  } : {}}  label="Telefon Nummer" variant="standard" onChange={e => setTelf(e.target.value)} value={telf} error={!telf && submitted} fullWidth />

			<TextField id="standard-basic" sx={props.glassUI === "true" ? {
			    input: { color: 'white' }, // Text color
			    '& label': {
			      color: 'white', // Label color
			    },
			    '& .MuiInput-underline:before': {
			      borderBottomColor: 'white', // Underline color before focus
			    },
			    '& .MuiInput-underline:after': {
			      borderBottomColor: 'white', // Underline color after focus
			    },
			    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			      borderBottomColor: 'white', // Underline color when hovering
			    },
			    '& .MuiFormHelperText-root': {
			      color: 'white', // Helper text color
			    },
			  } : {}} label="Fulde Navn" variant="standard" onChange={e => setFullName(e.target.value)} value={fullName} error={!fullName && submitted} fullWidth />



			<TextField id="standard-basic" sx={props.glassUI === "true" ? {
			    input: { color: 'white' }, // Text color
			    '& label': {
			      color: 'white', // Label color
			    },
			    '& .MuiInput-underline:before': {
			      borderBottomColor: 'white', // Underline color before focus
			    },
			    '& .MuiInput-underline:after': {
			      borderBottomColor: 'white', // Underline color after focus
			    },
			    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			      borderBottomColor: 'white', // Underline color when hovering
			    },
			    '& .MuiFormHelperText-root': {
			      color: 'white', // Helper text color
			    },
			  } : {}} label="kommentar" variant="standard" inputProps={{maxLength: 80}} onChange={e => setComments(e.target.value)} value={comments} fullWidth />




			<ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{marginLeft: 4, marginTop: 2}}>
			  <Button 
			    onClick={() => setIsPlanningForLater(false)} 
			    disabled={!props.isOpen} // Disable this button if props.isOpen is false
			    sx={{
			      color: !isPlanningForLater ? 'black' : 'white', // Black text for selected (now), white for unselected
			      backgroundColor: !isPlanningForLater ? 'white' : 'grey', // White background for selected (now)
			      '&:hover': {
			        backgroundColor: !isPlanningForLater ? 'rgba(255, 255, 255, 0.8)' : 'rgba(128, 128, 128, 0.8)', // Lighter on hover
			      },
			    }}
			  >
			    Nu
			  </Button>
			  <Button 
			    onClick={() => setIsPlanningForLater(true)} 
			    sx={{
			      color: isPlanningForLater ? 'black' : 'white', // Black text for selected (later), white for unselected
			      backgroundColor: isPlanningForLater ? 'white' : 'grey', // White background for selected (later)
			      '&:hover': {
			        backgroundColor: isPlanningForLater ? 'rgba(255, 255, 255, 0.8)' : 'rgba(128, 128, 128, 0.8)', // Lighter on hover
			      },
			    }}
			  >
			    Planlæg til senere
			  </Button>
			</ButtonGroup>



			{isPlanningForLater && (
				<>
		      <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 1 }}>
		        <InputLabel id="day-select-label"
		        sx={props.glassUI === "true" ? {
		          color: 'white',
		          '& .MuiOutlinedInput-notchedOutline': {
		            borderColor: 'white',
		          },
		          '&:hover .MuiOutlinedInput-notchedOutline': {
		            borderColor: 'white', // Border color on hover
		          },
		          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		            borderColor: 'white', // Border color when the input is focused
		          },
		        }: null}
		        >Day</InputLabel>
						<Select
						  labelId="day-select-label"
						  id="day-select"
						  value={selectedDay}
						  label="Dag"
						  sx={props.glassUI === "true" ? {
						    color: 'white',
						    '& .MuiOutlinedInput-notchedOutline': {
						      borderColor: 'white',
						    },
						    '&:hover .MuiOutlinedInput-notchedOutline': {
						      borderColor: 'white', // Border color on hover
						    },
						    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						      borderColor: 'white', // Border color when the input is focused
						    },
						  }: null}
						  onChange={(e) => {
						    const selected = getDays().find(day => day.value === e.target.value);
						    if (selected && selected.isClosed) {
						      alert('This day is closed. Please choose another day.');
						      return;
						    }
						    setSelectedDay(e.target.value);
						    // Update available hours based on the selected day
						    setAvailableHours(getHoursForDay(e.target.value));
						  }}
						>
						  {getDays().map((day, index) => (
						    <MenuItem key={index} value={day.value} disabled={day.isClosed}>
						      {day.label}
						    </MenuItem>
						  ))}
						</Select>
		      </FormControl>

		      <FormControl fullWidth sx={{ marginBottom: 2 }}>
		        <InputLabel id="time-select-label"
		        	sx={ props.glassUI === "true" ? {
					      color: 'white',
					      '& .MuiOutlinedInput-notchedOutline': {
					        borderColor: 'white',
					      },
					      '&:hover .MuiOutlinedInput-notchedOutline': {
					        borderColor: 'white', // Border color on hover
					      },
					      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					        borderColor: 'white', // Border color when the input is focused
					      },
					    }: null}
					    >Time</InputLabel>
		        <Select
						  labelId="time-select-label"
						  id="time-select"
						  value={selectedTime}
						  label="Time"
						  onChange={(e) => setSelectedTime(e.target.value)}
						  disabled={!selectedDay || availableHours.length === 0}
						  sx={ props.glassUI === "true" ? {
					      color: 'white',
					      '& .MuiOutlinedInput-notchedOutline': {
					        borderColor: 'white',
					      },
					      '&:hover .MuiOutlinedInput-notchedOutline': {
					        borderColor: 'white', // Border color on hover
					      },
					      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					        borderColor: 'white', // Border color when the input is focused
					      },
					    }: null}
						>
						  {availableHours.map((hour, index) => (
						    <MenuItem key={index} value={hour}>
						      {hour}
						    </MenuItem>
						  ))}
						</Select>
		      </FormControl>
		    </>
			)}

		</Box>
		{props.children}
		<Button
		  variant="outlined"
		  startIcon={<CheckOutIcon />}
		  onClick={submit}
		  sx={{ marginTop: "10px", width: { xs: "90%", md: "19vw" } }}
		  size="large"
		  disabled={delivery && !isAddressValid}
		>
		  Check Ud
		</Button>



	</>;
}