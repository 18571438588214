import React from "react";
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

const PlaceAutocomplete = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ["address"],
      componentRestrictions: { country: "dk" },
    },
    debounce: 300,
  });

  const registerRef = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
    props.setAddress(e.target.value);

    // Reset validation while typing
    props.setIsAddressValid(false);
    props.setAddressError("");
  };

  const handleFocus = () => {
    // If there is already a value in the input, trigger suggestions
    if (value && value.trim() !== '') {
      // The second argument "true" forces the hook to fetch suggestions
      setValue(value, true);
    }
  };

  const handleSelect = ({ description }) => async () => {
    setValue(description, false);
    props.setAddress(description);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: description });
      const addressComponents = results[0].address_components;
      const streetNumber = addressComponents.find(comp => comp.types.includes("street_number"));
      const route = addressComponents.find(comp => comp.types.includes("route"));

      if (streetNumber && route) {
        props.setIsAddressValid(true);
        props.setAddressError("");
      } else {
        props.setIsAddressValid(false);
        props.setAddressError("Vælg venligst en fuld adresse, inklusive gadenavn og husnummer.");
      }
    } catch (error) {
      console.error('Error: ', error);
      props.setIsAddressValid(false);
      props.setAddressError("Kunne ikke verificere adressen. Vælg venligst et forslag fra rullemenuen.");
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li
          key={id}
          onClick={handleSelect(suggestion)}
          style={{
            borderBottom: "1px solid #e7ebf0",
            cursor: "pointer",
            padding: "8px 0"
          }}
        >
          <strong>{main_text}</strong> <small>{secondary_text ? ` ${secondary_text}` : ""}</small>
        </li>
      );
    });

  const handleBlur = () => {
    if (!props.isAddressValid) {
      props.setAddressError('Vælg venligst en gyldig adresse fra forslagene.');
    }
  };

  // Styles
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  };

  const inputStyle = {
    width: props.glassUI === "true" ? '18vw' : '16vw',
    width: "100%",
    padding: '8px',
    marginBottom: '5px',
    border: props.addressError ? '1px solid red' : '1px solid #ccc',
    background: props.glassUI === "true" ? 'rgba(255,255,255,0.1)' : '#fff',
    color: props.glassUI === "true" ? 'white' : 'inherit',
    outline: 'none'
  };

  const suggestionListStyle = {
    width: '100%',
    maxWidth: '360px',
    backgroundColor: '#ffffff',
    position: 'absolute',
    top: '100%',
    left: 0,
    border: '1px solid #ccc',
    borderRadius: '4px',
    overflow: 'auto',
    maxHeight: '300px',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    zIndex: 9999
  };

  const errorTextStyle = {
    color: 'red',
    fontSize: '0.9em',
    marginTop: '-3px',
    marginBottom: '5px'
  };

  return (
    <div style={containerStyle} ref={registerRef}>
      <label htmlFor="full-address" style={{ color: props.glassUI === "true" ? 'white' : 'inherit' }}>
        Din Fulde Adresse
      </label>
      <input
        id="full-address"
        type="text"
        value={value ? value : props.theValue}
        onChange={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={!ready}
        style={inputStyle}
      />
      {props.addressError && <div style={errorTextStyle}>{props.addressError}</div>}

      {status === 'OK' && (
        <ul style={suggestionListStyle}>
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default PlaceAutocomplete;
